import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import socketIOClient from "socket.io-client";
import Dashboard from './Dashboard';
import ChatInterface from './ChatInterface';
import Sidebar from './Sidebar';
import apiClient from '../../apiClient';

const SOCKET_IO_ENDPOINT = "https://life.activatedhuman.earth";

const Portal = () => {
  const [threads, setThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [showDashboard, setShowDashboard] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      fetchThreads();
      const socket = socketIOClient(SOCKET_IO_ENDPOINT, {
        path: '/api/socket.io',
        withCredentials: true
      });
      socket.on("connect", () => {
        console.log("Connected to server");
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [isAuthenticated]);

  const fetchThreads = async () => {
    try {
      const response = await apiClient.get('/portal/threads');
      setThreads(response.data);
      if (response.data.length > 0 && !currentThreadId) {
        setCurrentThreadId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };

  const startNewConversation = async () => {
    try {
      const response = await apiClient.post('/portal/threads');
      setThreads([...threads, response.data]);
      setCurrentThreadId(response.data.id);
      setShowDashboard(false);
    } catch (error) {
      console.error('Error creating new thread:', error);
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        threads={threads}
        currentThreadId={currentThreadId}
        setCurrentThreadId={setCurrentThreadId}
        setShowDashboard={setShowDashboard}
        startNewConversation={startNewConversation}
      />
      <div className="flex-1 flex flex-col">
        <header className="bg-gradient-to-r from-blue-500 via-blue-600 to-purple-700 text-white shadow-sm p-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">Life Framework</h1>
            <div className="flex items-center space-x-4">
              <button className="bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-2">
                <span className="sr-only">Settings</span>
                {/* Replace with actual Settings icon */}
                ⚙️
              </button>
              <button className="bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full px-3 py-1">
                Log Out
              </button>
            </div>
          </div>
          {user && <p className="text-sm mt-1">Welcome, {user.email}</p>}
        </header>
        <main className="flex-1 overflow-y-auto p-6">
          {showDashboard ? (
            <Dashboard 
              setShowDashboard={setShowDashboard}
              startNewConversation={startNewConversation}
            />
          ) : (
            <ChatInterface currentThreadId={currentThreadId} />
          )}
        </main>
      </div>
    </div>
  );
};

export default Portal;