import React from 'react';
import { Card, CardHeader, CardContent } from "../ui/card";
import { Button } from "../ui/button";

const Dashboard = ({ setShowDashboard, startNewConversation }) => {
  const handleStartConversation = () => {
    startNewConversation();
    setShowDashboard(false);
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Today's Focus</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600 mb-4">What's your main goal for today?</p>
            <Button onClick={handleStartConversation}>
              Start Today's Conversation
            </Button>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Recent Progress</h2>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              <li className="flex items-center">
                <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                <span>Completed daily reflection for 5 days straight</span>
              </li>
              <li className="flex items-center">
                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                <span>Set a new personal goal: Learn to play guitar</span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Upcoming Milestones</h2>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              <li className="flex items-center">
                <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
                <span>Present project at work (in 3 days)</span>
              </li>
              <li className="flex items-center">
                <span className="w-2 h-2 bg-purple-500 rounded-full mr-2"></span>
                <span>Start meditation challenge (in 1 week)</span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Inspirational Quote</h2>
          </CardHeader>
          <CardContent>
            <blockquote className="italic text-gray-600">
              "The only way to do great work is to love what you do." - Steve Jobs
            </blockquote>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;