import React from 'react';
import { Home, MessageCircle, BarChart2, Plus } from 'lucide-react';
import { Button } from "../ui/button";

const Sidebar = ({ threads, currentThreadId, setCurrentThreadId, setShowDashboard, startNewConversation }) => {
  return (
    <div className="w-64 bg-gray-900 text-white flex flex-col h-screen">
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Life Framework</h2>
      </div>
      <nav className="flex-1 overflow-y-auto">
        <ul>
          <li>
            <Button
              onClick={() => setShowDashboard(true)}
              variant="ghost"
              className="flex items-center w-full justify-start text-white"
            >
              <Home className="h-5 w-5 mr-3" />
              Dashboard
            </Button>
          </li>
          <li className="mt-4 mb-2 px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
            Conversations
          </li>
          <li>
            <Button
              variant="ghost"
              className="flex items-center w-full justify-start text-white"
              onClick={startNewConversation}
            >
              <Plus className="h-5 w-5 mr-3" />
              New Conversation
            </Button>
          </li>
          {threads.map((thread) => (
            <li key={thread.id}>
              <Button
                variant={thread.id === currentThreadId ? "secondary" : "ghost"}
                className="flex items-center w-full justify-start text-white"
                onClick={() => {
                  setCurrentThreadId(thread.id);
                  setShowDashboard(false);
                }}
              >
                <MessageCircle className="h-5 w-5 mr-3" />
                <div className="flex-1 text-left">
                  <p className="font-medium">{thread.title}</p>
                </div>
              </Button>
            </li>
          ))}
          <li className="mt-4 mb-2 px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
            Insights
          </li>
          <li>
            <Button
              variant="ghost"
              className="flex items-center w-full justify-start text-white"
              onClick={() => {/* TODO: Implement insights view */}}
            >
              <BarChart2 className="h-5 w-5 mr-3" />
              Progress & Growth
            </Button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;