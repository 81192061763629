import axios from 'axios';

const apiClient = axios.create({
  // baseURL: process.env.BASE_URL_API,
  baseURL: "https://a.life.activatedhuman.earth/api"
});

apiClient.interceptors.request.use(config => {
  const sessionToken = localStorage.getItem('session_token');
  if (sessionToken) {
    config.headers['Authorization'] = `Bearer ${sessionToken}`;
  } else {
    console.log('No token found in localStorage.'); // Debug: Log if no token is found
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default apiClient;
