import React, { useState, useEffect } from 'react';
import { Send } from 'lucide-react';
import { Button } from "../ui/button";
import apiClient from '../../apiClient';

const ChatInterface = ({ currentThreadId }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    if (currentThreadId) {
      fetchMessages(currentThreadId);
    }
  }, [currentThreadId]);

  const fetchMessages = async (threadId) => {
    try {
      const response = await apiClient.get(`/portal/threads/${threadId}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSend = async () => {
    if (!inputText && !attachment) return;

    const formData = new FormData();
    formData.append('text', inputText);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      const response = await apiClient.post(`/portal/threads/${currentThreadId}/messages`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessages([...messages, response.data]);
      setInputText('');
      setAttachment(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleAttachment = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setAttachment(file);
    } else {
      alert('Please select an image file');
    }
  };

  const renderMessageContent = (content) => {
    return content.map((item, index) => {
      if (item.type === 'text') {
        return <p key={index} className="mb-2">{item.text}</p>;
      } else if (item.type === 'image_url') {
        return <img key={index} src={item.image_url.url} alt="attachment" className="mt-2 max-w-xs rounded-lg shadow-md" />;
      }
    });
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((msg, index) => (
          <div key={index} className={`mb-4 ${msg.role === 'user' ? 'text-right' : 'text-left'}`}>
            <div className={`inline-block p-3 rounded-lg ${msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white shadow-md'}`}>
              {renderMessageContent(msg.content)}
            </div>
          </div>
        ))}
      </div>
      <div className="p-4 bg-white border-t">
        <div className="flex items-center">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="flex-1 border rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
          />
          <label className="cursor-pointer bg-gray-200 text-gray-700 p-2 hover:bg-gray-300 transition-colors duration-200">
            <input type="file" accept="image/*" onChange={handleAttachment} className="hidden" />
            📎
          </label>
          <Button onClick={handleSend} className="rounded-r-lg">
            <Send className="h-5 w-5 mr-2" />
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;